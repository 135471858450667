/* eslint-disable no-undef*/
/* eslint-disable max-lines-per-function*/
/* eslint-disable no-unused-vars*/
// @ts-check

import './emosclickmonitor.js';
import { langConfig } from './config/langConfig';
// import emos3 library
import  './vendor/emos3.js';
import './vendor/swts.js';
import { checkCookie, setCookie, getCookie } from './setCookie';

// get langCode from html lang attribute or set default
export const langCode = document.documentElement.lang || 'de';

export const getlangConfig = (/** @type {string} */ key, lang=langCode, theLangconfig=langConfig) => {
    return theLangconfig[lang] ? theLangconfig[lang][key] : null;
};
/**
 * normalize path, remove slashes and index.html
 * @param {string} prefix
 * an optional prefix to be added to the path
 * @returns a normalized path + prefix
 */
export function normalizedPath(prefix=getlangConfig('domainid'), path=window.location.pathname) {
    // remove trailing slash if present
    path = path.replace(/[/]*$/g, '');
    // get last part of path
    const lastPart = path.split('/').pop();
    // return if lastPart is undefined
    if (!lastPart) {
        return prefix;
    }
    const lastPartHasExtension = lastPart.indexOf('.') > -1;
    // if last part has extension, remove extension
    if (lastPartHasExtension) {
        // remove extension
        let pathArray = path.split('/');
        pathArray.pop();
        path = pathArray.join('/');
    }
    return (prefix + path);
}
/**
 * a function to get the get parameter from the url
 * @param {string} searchParam name of the parameter
 * @returns value of the parameter
 */
export const urlSearchValue = (searchParam = '', urlParams = window.location.search) => {
    const theurlParams = new URLSearchParams(urlParams);
    return theurlParams.get(searchParam) || '';
};
/**
 * a function to parse a json string and return an object
 * @param {string} jsonString
 * a json string
 * @returns
 * an object
 * @toDo
 * refactor misuse of try/catch
 */
const normalizeJson = (jsonString) => {
    try {
        jsonString = jsonString.replace(/'/g, '"');
        jsonString = JSON.parse(jsonString);
    } catch (e) {
        console.warn('t_extern.js: targetData is not an object');
    }
    return jsonString;
};
/**
 * call the submitemos function to send the marker data
 * @param {string} markername
 * name of the marker
 */
const sendmarker = (markername) => {
    if (!markername) {
        return;
    }
    let emosObject = {
        content:normalizedPath(getlangConfig('domainid')),
        siteid: getlangConfig('siteid'),
        marker: markername,
        rqtype : 'hiddenpi'
    };
    submitemos(emosObject);
};
/**
 * call the submitemos function to send the target data
 * @param {object} targetData
 */
const sendTarget = (targetData) => {
    targetData = normalizeJson(targetData);
    let emosObject = {
        content:normalizedPath(getlangConfig('domainid')),
        siteid: getlangConfig('siteid'),
        Target: targetData,
        rqtype : 'hiddenpi'
    };
    submitemos(emosObject);
};

const sendVisitorTagger = function () {
    var value = getCookie('VisitorTagger');
    if(value && value.indexOf('User') !== -1){
        sendcgroup(value);
    }
};
const sendcgroup = function (cgroupval) {
    var emosprocgroup = {};
    emosprocgroup = {
        content:normalizedPath(getlangConfig('domainid')),
        siteid: getlangConfig('siteid'),
        cgroup: cgroupval,
        rqtype : 'hiddenpi'
    };
    submitemos(emosprocgroup);

};

//function to send a abtest as an array
const sendABTest = (/** @type {any[]} */ abtest) => {
    let emosObject = {
        content:normalizedPath(getlangConfig('domainid')),
        siteid: getlangConfig('siteid'),
        abtest: abtest,
        rqtype : 'hiddenpi'
    };
    submitemos(emosObject);
};

/**
 * submit an emos object to the emos3 library
 * @param {*} emosObject
 */
export const submitemos = (emosObject) => {
    const emosIsOkay = typeof emosObject === 'object' && Object.prototype.hasOwnProperty.call(emosObject, 'content') && Object.prototype.hasOwnProperty.call(emosObject, 'siteid') && emos3;
    if (emosIsOkay) {
        // @ts-ignore
        emos3.send(emosObject);
    }else{
        console.warn('t_extern.js: emosObject is not an object or properties are missing');
    }
};

/**
 * call the send functions after collecting the data attributes
 * @param {Event} event
 * @param {*} attribute
 */
// eslint-disable-next-line complexity
const sendDataAttribut = (event, attribute) => {
    // if target is not an element, return
    if (!(event.target instanceof Element)) {
        return;
    }
    const { target } = event;
    const { dataset } = target.closest(attribute) || {};
    const { emosClickmarker } = dataset || {};
    const { buy } = dataset || {};
    const { emosTarget } = dataset || {};
    const { emosKeydownmarker } = dataset || {};

    if (emosClickmarker) {
        sendmarker(emosClickmarker);
    }
    if (emosKeydownmarker){
        sendmarker(emosKeydownmarker);
    }
    if (buy) {
        sendmarker(normalizedPath(getlangConfig('markerprefix'))+'/order/'+dataset.buy);
    }
    if (emosTarget) {
        sendTarget(dataset.emosTarget);
    }
};
/**
* set a clickmarker to all domainchecker if absent
*/
const setDomainCheckerClickmarker = () => {
    /** @type {HTMLElement|null} */
    const domainChecker  = document.querySelector('strato-domainchecker button');
    if (domainChecker) {
        const { dataset } = domainChecker;
        const { emosClickmarker } = dataset || {};
        if (!emosClickmarker) {
            domainChecker.dataset.emosClickmarker = normalizedPath(getlangConfig('markerprefix'))+'/domainchecker/onsubmit';
        }
    }
};

/**
* set a clickmarker to all domainchecker textfield if absent
*/
const setDomainCheckerTextfieldClickmarker = () => {
    /** @type {HTMLElement|null} */
    const domainChecker = document.querySelector('strato-domainchecker input.searchfield');
    if (domainChecker) {
        const { dataset } = domainChecker;
        const { emosClickmarker } = dataset || {};
        if (!emosClickmarker) {
            domainChecker.dataset.emosClickmarker = normalizedPath(getlangConfig('markerprefix'))+'/domainchecker/textfield';
        }
    }
};
/**
*set a keydownmarker to all domainchecker textfield if absent
*/
const setDomainCheckerTextfieldKeydownmarker = () => {
    /** @type {HTMLInputElement|null} */
    const domainChecker = document.querySelector('strato-domainchecker input.searchfield');
    if (domainChecker) {
        const { dataset } = domainChecker;
        const { emosKeydownmarker } = dataset || {};
        //get value of textfield
        const { value } = domainChecker;
        // if value has a dot, set keydownmarker
        if (value.indexOf('.') > -1) {
            domainChecker.dataset.emosKeydownmarker = normalizedPath(getlangConfig('markerprefix'))+'/domainchecker/textfield/PUNKT';
        }
        // if value has a .de .nl .es .se, set keydownmarker
        if (value.indexOf('.de') > -1) {
            domainChecker.dataset.emosKeydownmarker = normalizedPath(getlangConfig('markerprefix'))+'/domainchecker/textfield/PUNKTDE';
        }
        if (value.indexOf('.nl') > -1) {
            domainChecker.dataset.emosKeydownmarker = normalizedPath(getlangConfig('markerprefix'))+'/domainchecker/textfield/PUNKTNL';
        }
        if (value.indexOf('.es') > -1) {
            domainChecker.dataset.emosKeydownmarker = normalizedPath(getlangConfig('markerprefix'))+'/domainchecker/textfield/PUNKTES';
        }
        if (value.indexOf('.se') > -1) {
            domainChecker.dataset.emosKeydownmarker = normalizedPath(getlangConfig('markerprefix'))+'/domainchecker/textfield/PUNKTSE';
        }
    }
};

// submit object on page load
init();

// add event listener to all elements with data-emos-clickmarker or data-buy or data-emos-target
document.addEventListener('mousedown', (element,attribute='[data-emos-clickmarker],[data-buy],[data-emos-target]') => {
    sendDataAttribut(element,attribute);
});
// add event listener to all elements with data-emos-keydownmarker attribute
document.addEventListener('keyup', (element,attribute='[data-emos-keydownmarker]') => {
    setDomainCheckerTextfieldKeydownmarker();
    sendDataAttribut(element,attribute);
});

/**
 * a function to initialize econda tracking and sendab the referrer via refmarker get parameter
 */
function init() {
    submitemos({
        content: normalizedPath(getlangConfig('domainid')),
        siteid: getlangConfig('siteid'),
        pageId: normalizedPath(getlangConfig('domainid')).toLocaleUpperCase()
    });
    //encode with https://www.urlencoder.org/ POR-13247
    // https://www.strato.de/server/linux-vserver/?refmarker=strato_de%2Fserver%2Flinux-vserver%2Faktion%2FUmleitung%2F092021%2Flinux
    if (urlSearchValue('refmarker')) {
        sendmarker(urlSearchValue('refmarker'));
    }
    sendVisitorTagger();
    setDomainCheckerClickmarker();
    setDomainCheckerTextfieldClickmarker();
    setDomainCheckerTextfieldKeydownmarker();
}

/**
 * a function to collect all data-abtest and return array of all abtests
 * @returns {array} abtests
 */
const getabtests = () => {
    const abtests = [];
    /** @type {NodeListOf<HTMLElement>|null} */
    const abtestElements = document.querySelectorAll('[data-abtest][data-abtest-case]');
    abtestElements.forEach(element => {
        const { dataset } = element;
        const { abtest } = dataset || {};
        //check if abtest is already in array
        if (abtest && abtests.indexOf(abtest) === -1) {
            abtests.push(abtest);
        }
    } );
    return abtests;
};

/**
 * a function to generate a random number between min and max
 * @param {number} min
 * @param {number} max
 * @returns {number}
*/
const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * function to get all cases cases of an abtest
 * @param {string} testname
 * @returns {array} array of all cases
 */
const getabtestcases = (testname) => {
    /** @type {NodeListOf<HTMLElement>|null} */
    const abtestElements = document.querySelectorAll(`[data-abtest="${testname}"]`);
    const abtestCases = [];
    abtestElements.forEach(element => {
        const { dataset } = element;
        const { abtestCase } = dataset || {};
        // push abtestCase to array if abtestCase is not already in array
        if (abtestCase && abtestCases.indexOf(abtestCase) === -1) {
            abtestCases.push(abtestCase);
        }
    });
    return abtestCases;
};

/**
 * function to get the abtestdescription for a abtest
 * @param {string} testname
 * @param {string} testcase
 * @returns {string} abtestdescription
 *
*/
const getabtestdescription = (testname, testcase) => {
    /** @type {HTMLInputElement|null} */
    const abtestElements = document.querySelector(`[data-abtest="${testname}"][data-abtest-case="${testcase}"]`);
    // if abtestElements is not null, get the description
    if (abtestElements) {
        return abtestElements.dataset.abtestDescrtiption || '';
    }
    return '';
};

/**
 * function to modify the class attribute of a given selector
 * @param {string} selector
 * @param {string} className
 * @param {string} newClassName
 * @returns {void}
 */
const modifyClass = (selector, className, newClassName) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach(element => {
        element.classList.remove(className);
        element.classList.add(newClassName);
    } );
};

/**
 * check if all abtest elements have an data-abtest-case attribute
 * @returns {void}
 * @throws {Error}
*/
const checkabtestcases = () => {
    /** @type {NodeListOf<HTMLElement>|null} */
    const abtestElements = document.querySelectorAll('[data-abtest]');
    abtestElements.forEach(element => {
        const { dataset } = element;
        const { abtestCase } = dataset || {};
        if (!abtestCase) {
            console.info('data-abtest-case attribute is missing');
        }
    });
};

/**
 * function to count the cases for a abtest
 * @param {string} testname
 * @returns {number} number of cases
*/
const countabtestcases = (testname) => {
    /** @type {NodeListOf<HTMLElement>|null} */
    const abtestElements = document.querySelectorAll(`[data-abtest="${testname}"]`);
    /** @param {array} abtestCases */
    const abtestCases = [];
    abtestElements.forEach(element => {
        const { dataset } = element;
        const { abtestCase } = dataset || {};
        // push abtestCase to array if abtestCase is not already in array
        if (abtestCase && abtestCases.indexOf(abtestCase) === -1) {
            abtestCases.push(abtestCase);

        }
    } );
    return abtestCases.length;
};

const initabtests = () => {
    /** @param {array} abtests */
    const abtests = getabtests();
    abtests.forEach(/** @type {string} abtest */ abtest => {
        const abtestCases = getabtestcases(abtest);
        const randomCase = abtestCases[random(0, abtestCases.length -1)];
        // get the case from the cookie or set the generated random case
        /** @var {number} activeCase */
        const activeCase = getCookie('abtest_'+abtest) || randomCase;
        const abtestDescription = getabtestdescription(abtest, activeCase);
        // send the abtest to econda
        sendABTest([abtest, abtestDescription]);
        // if cookie does not exist, set cookie with randomCase
        if (!checkCookie('abtest_'+abtest)) {
            setCookie('abtest_'+abtest, randomCase, 365);
        }
        // if the abtest is not active in the current case, hide the element
        if (activeCase !== abtest) {
            modifyClass(`[data-abtest="${abtest}"]:not([data-abtest-case="${activeCase}"])`, 'show', 'd-none');
            modifyClass(`[data-abtest="${abtest}"][data-abtest-case="${activeCase}"]`, 'd-none', 'show');
        }
    } );
};

/**
 * create a 2 dimensional array with all abtests, their cases and their descriptions
 * @returns {array} array of all abtests
 * @throws {Error}
 * */
const getABTests = () => {
    /** @param {array} abtests */
    const abtests = getabtests();
    /** @param {array} abtestArray */
    const abtestArray = [];
    abtests.forEach(/** @type {string} abtest */ abtest => {
        const abtestCases = getabtestcases(abtest);
        abtestCases.forEach(/** @type {string} abtestCase */ abtestCase => {
            const abtestDescription = getabtestdescription(abtest, abtestCase);
            abtestArray.push([abtest, abtestCase, abtestDescription]);
        });
    });
    return abtestArray;
};

checkabtestcases();
initabtests();

// create a iffee for the swts script
(function() {
    if (checkCookie('SWTSdisable')) {
        console.warn('t_extern.js: swts is disabled');
        return;
    }
    if (window.location.hostname != getlangConfig('siteid')) {
        console.warn('t_extern.js: swts is only available on the main domain');
        return;
    }
    // @ts-ignore
    const swtsTracker = Swts.getTracker('/swts/', 1);
    swtsTracker.trackPageView();
    swtsTracker.enableLinkTracking();
})();

