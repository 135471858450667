//@ts-check
/**
 * Writes a cookie with the given name, value, and expiration days.
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} days - The number of days until the cookie expires.
 * @returns {void}
 */
export const setCookie = (name, value, days) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + expirationDate.toUTCString();
    document.cookie = name + '=' + encodeURIComponent(value) + ';' + expires + ';path=/';
};
/**
 * Retrieves the value of a cookie by name.
 * @param {string} name - The name of the cookie.
 * @returns {string} - The value of the cookie, or an empty string if the cookie is not found.
 */
export const getCookie = (name) => {
    // Get all cookies as a string
    const cookies = document.cookie || '';
    // Split the cookies string into individual cookie key-value pairs
    const cookieArray = cookies.split(';');
    // Loop through each cookie to find the one with the specified name
    for (const cookie of cookieArray) {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        if (cookieName === name) {
            return decodeURIComponent(cookieValue); // Return the value of the specified cookie
        }
    }
    // Cookie with the specified name not found, return an empty string
    return '';
};

/**
 * Deletes a cookie with the given name.
 *
 * @param {string} name - The name of the cookie to delete.
 * @return {void} No return value.
 */
export const deleteCookie = (name) => {
    // Set the cookie to expire immediately by setting its expiration date to a past time.
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

/**
 * Checks if a cookie with the given name exists.
 *
 * @param {string} name - The name of the cookie to check.
 * @return {boolean} Returns true if the cookie exists, false otherwise.
 */
export const checkCookie = (name) => {
    // Get all cookies as a string
    const cookies = document.cookie;
    // Split the cookies string into individual cookie key-value pairs
    const cookieArray = cookies.split(';');
    // Loop through each cookie to check if the name matches
    for (const cookie of cookieArray) {
        const [cookieName] = cookie.trim().split('=');
        if (cookieName === name) {
            return true; // Cookie with the specified name found
        }
    }
    // Cookie with the specified name not found
    return false;
};

/**
 * Retrieves all the cookies from the current document.
 *
 * @return {Array} An array containing all the cookies in the document.
 */
export const getAllCookies = () => {
    // Get all cookies as a string
    const cookies = document.cookie || '';
    // Split the cookies string into individual cookie key-value pairs
    const cookieArray = cookies.split(';');
    // Create an array to store the final result
    const result = [];
    // should return an empty array if there are no cookies
    if (cookieArray[0] === '') {
        return result;
    }
    // Loop through each cookie to parse and add it to the result array
    for (const cookie of cookieArray) {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        result.push({ name: cookieName, value: cookieValue });
    }
    return result;
};

//setCookie('yxyxyxyx', 'huhuhuhuhuhuuu', 30);

//deleteCookie('yxyxyxyx');

//console.log(getCookie('cookiecookie'));

//console.log(checkCookie('yxyxyxyx'));

//console.log(getAllCookies());

//console.log(getCookie('abtest_DE_2112_Home'));
